import { ReactElement } from "react";

class NewsItem {
  private id: string;
  private title: string | null;
  private text: string | ReactElement;
  private date: Date | null;
  private link: string | null;
  private more: boolean;

  constructor (id: string, text: string | ReactElement, date: Date | null = null, link: string | null = null, isMore = false) {
    this.id = id;
    this.date = date;
    this.text = text;
    this.link = link;
    this.more = isMore;

    this.title = null;
  }

  public isDate(): boolean {
    return this.date !== null;
  }

  public isLink(): boolean {
    return this.link !== null;
  }

  public isTitle(): boolean {
    return this.title !== null;
  }

  public isMore(): boolean {
    return this.more;
  }

  public getId(): string {
    return this.id;
  }

  public getDate(): Date | null{
    return this.date;
  }

  public setDate(_date: Date): void {
    this.date = _date;
  }

  public getText(): string | ReactElement {
    return this.text;
  }

  public setText(_text: string): void {
    this.text = _text;
  }

  public getLink(): string | null {
    return this.link;
  }

  public setLink(_link: string): void {
    this.link = _link;
  }

  public getTitle(): string | null{
    return this.title;
  }

  public setTitle(_title: string): void {
    this.title = _title;
  }
}

export { NewsItem };
