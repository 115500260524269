import React, { ReactElement, useContext } from "react";
import Moment from "react-moment";
import { Link } from "gatsby";

import { NewsItem } from "../libs/news-item";
import { LinkData } from "@chweb/commonlib";

import { LocaleContext, getLocalizedValue } from "../components/locale-context";
import { Capitalize } from "@chweb/commonui";
import { getOneNewsLabels } from "../queries/get-one-news-labels";

interface DateElementProps {
  date: Date,
  link?: string
}

function DateElement ({ date, link }: DateElementProps): ReactElement {
  const { locale } = useContext(LocaleContext);
  const momentLocale = locale === 'zh' ? 'zh-cn' : locale;
  try {
    require(`moment/locale/${momentLocale}`);
  } catch (e) {}

  let dateString = <Moment locale = { momentLocale }
    format="MMMM, YYYY"
    style = {{ textTransform: 'capitalize' }}
  >
    { date }
  </Moment>;

  if (link) {
    dateString = <Link to = { link }>
      { dateString }
    </Link>;
  }
  return (<b>{ dateString }</b>);
}

interface MoreLinkElementProps {
  link: LinkData;
}

function MoreLinkElement ({ link }: MoreLinkElementProps): ReactElement {
  return (
    <Link to = { link.getLink() }>
      <Capitalize text = { link.getLabel() } />
    </Link>
  );
}

interface OneNewsSectionProps {
  content: NewsItem 
}

function OneNewsSection ({ content }: OneNewsSectionProps): ReactElement {
  const labels = getOneNewsLabels();

  if (!content) {
    return (<p>&nbsp;</p>);
  }

  const { locale } = useContext(LocaleContext);

  const localLabels = getLocalizedValue(labels, locale);
  // link
  let moreLinkElement = null;
  if (content.isMore()) {
    const linkData = new LinkData(content.getId(), localLabels.oneNewsComponentFullStory);
    moreLinkElement = <MoreLinkElement link = { linkData } />;
  }
  // date
  let dateElement = null;
  if (content.isDate()) {
    dateElement = <DateElement
      date = { content.getDate() as Date}
      link = { content.getId() } />;
  }

  let titleElement = null;
  if (content.isTitle()) {
    titleElement = <>
        <Link to = { content.getId() }><h3>{ content.getTitle() }</h3></Link>
        <p>{ dateElement }</p>
      </>;
  } else {
    titleElement = dateElement;
  }

  const contentText = content.getText();
  const contentWidget = typeof contentText === 'object'
    ? <div>{ contentText }</div>
    : <div dangerouslySetInnerHTML = { { __html: content.getText() as string } } />;

  return (
    <div className = "w3-padding-16">
      { titleElement }
      { contentWidget }
      { moreLinkElement }
    </div>
  );
}

export default OneNewsSection;
