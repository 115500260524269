import React from 'react';
import PubSub from 'pubsub-js';

import { GatsbyImage as Img } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { TileItem } from '../libs/tile-data';
import { localizeUrl } from '../libs/locale';
import ColumnsSection from '../components/columns-section';
import WithMargins from '../components/with-margins';
import { isMarkUpImage, MarkUpImage } from './markup-image';

import { ImagePopupEvent, ContentWithDescription } from './image-popup';

function handleClick (imageInfo: ContentWithDescription): void {
  PubSub.publishSync(ImagePopupEvent, imageInfo);
}

interface TileProps {
  data: TileItem,
  locale: string,
  height: string 
}

export function Tile ({ data, locale, height }: TileProps): React.ReactElement {
  const { text, tooltip, link, image } = data;

  const imageObject = isMarkUpImage(image)
    ? <MarkUpImage image = { image } />
    : <Img image = { image } style = {{ width: '100%' }} title = { tooltip } alt = { tooltip } />;

  const textStyle = link ? 'w3-large cpcolor-text-brown w3-opacity-off' : 'w3-opacity-off';

  const tileContent = <div
    onClick = { link ? undefined : () => handleClick(new ContentWithDescription(tooltip, imageObject)) }
  >
    { imageObject }
    <div className ="w3-display-bottomleft w3-container w3-white w3-opacity-min"
      style = {{ width: '100%', opacity: '0.9' }}>
      <p className = { textStyle } style = {{ paddingTop: '5px', paddingBottom: '5px', margin: '0' }}>
        { text }
      </p>
    </div>
  </div>;

  const tileStyle: { cursor: string, overflow: string, height?: string } = {
    cursor: 'pointer', overflow: 'hidden', height: undefined
  };
  if (height) {
    tileStyle.height = height;
  }

  return (
    <WithMargins margin="2%">
      <div className="w3-display-container w3-hover-opacity w3-slow-hover" style = { tileStyle } >
        { link ? <Link to = { localizeUrl(link, locale, true) } >{ tileContent }</Link> : tileContent }
      </div>
    </WithMargins>
  );
}

interface TilesProps {
  tiles: TileItem[];
  locale: string;
  height?: string;
  width?: string;
}

export function Tiles ({ tiles, locale, height, width }: TilesProps): React.ReactElement {
  const cols = tiles.map((t, i) => <Tile key={i}  data={t} locale={locale} height={height || ''} />);
  if (width) {
    return <div style = {{ maxWidth: width, margin: 'auto' }}>
      <ColumnsSection cols = { cols } />
    </div>;
  } else { // occupying 100%
    return <ColumnsSection cols = { cols } />;
  }
}
