import React from 'react';

import { IGatsbyImageData } from 'gatsby-plugin-image';

function findInProps (object: any, elemType: string, propertyName: string): any {
  if (object.type === elemType) {
    if (object.props && (propertyName in object.props)) {
      return object.props[propertyName];
    }
  } else if (object.props && object.props.children) {
    const children = object.props.children;
    for (let i = 0, m = children.length; i < m; ++i) {
      const found = findInProps(children[i], elemType, propertyName);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

function getMarkUpImage (image: IGatsbyImageData): any {
  return findInProps(image, 'a', 'children');
}

export function isMarkUpImage (image: IGatsbyImageData): boolean {
  return getMarkUpImage(image) !== null;
}

interface MarkUpImageProps {
  image: IGatsbyImageData
}

export function MarkUpImage ({ image }: MarkUpImageProps): React.ReactElement {
  const MAX_IMAGE_WIDTH_PX = 1000;
  const children = findInProps(image, 'a', 'children');
  const clonedChildren: React.ReactElement[] = [];
  React.Children.forEach(children, c => {
    if (!React.isValidElement(c)) {
      return;
    }
    if (c.type === 'img') {
      const imageElement = c as unknown as React.ReactElement<HTMLImageElement>;
      clonedChildren.push(React.cloneElement(imageElement, {
        sizes: `(max-width: ${MAX_IMAGE_WIDTH_PX}px) 100vw, ${MAX_IMAGE_WIDTH_PX}px`
      }));
    } else {
      clonedChildren.push(React.cloneElement(c));
    }
  });
  return <div
    className = "w3-image"
    style = {{ position: 'relative' }}
  >
    { clonedChildren }
  </div>;
}
