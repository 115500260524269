import React from 'react';

class ColumnSectionStyle {
  private static readonly minCols = 0;
  private static readonly colStyles = ['w3-rest', 'w3-rest', 'w3-half', 'w3-third', 'w3-quarter'];

  static getStyle (colCount: number): string {
    const minCols = ColumnSectionStyle.minCols;
    const maxCols = ColumnSectionStyle.minCols +
            ColumnSectionStyle.colStyles.length;

    if (colCount < minCols || colCount > maxCols) {
      console.error('Unsupported number of columns: ' + colCount);
      return '';
    }

    const styleIndex = colCount - minCols;
    return ColumnSectionStyle.colStyles[styleIndex];
  }
}

interface ColumnsSectionProps {
  cols: React.ReactElement [] 
}

class ColumnsSection extends React.Component<ColumnsSectionProps> {
  render () {
    const colCount = this.props.cols.length;
    const columnStyle = ColumnSectionStyle.getStyle(colCount);

    const Columns = ({ colData }: {colData: React.ReactElement[]}) => (
            <>{
              colData.map((col, index) => (
                <div className={columnStyle} key={index.toString()}>{col}</div>
              ))
            }</>
    );

    return (
      <div className="w3-row">
        <Columns colData = {this.props.cols} />
      </div>
    );
  }
}

export default ColumnsSection;
