import React, {PropsWithChildren} from 'react';

type WithMarginsProps = PropsWithChildren<{
  margin: string
}>;

export default class WithMargins extends React.Component<WithMarginsProps> {
  render () {
    return (
      <div style = {{
        marginLeft: this.props.margin,
        marginRight: this.props.margin
      }}>
        { this.props.children }
      </div>
    );
  }
}
