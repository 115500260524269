// various utility functions

export type SearchObject = Record<string, unknown>;
export type SearchArray = Array<SearchObject>;

// search for values of a given key in a nested object
export function deepSearch(obj: SearchObject| SearchArray | null | undefined, searchKey: string, keyBlackList: {[key: string]: boolean} = {}): any {
  if (!obj) return null;

  if (Array.isArray(obj)) {
    for (let i = 0, m = obj.length; i < m; ++i) {
      const potentialMatch = deepSearch(obj[i], searchKey, keyBlackList);
      if (potentialMatch) {
        return potentialMatch;
      }
    }
  }
  else if (typeof obj === 'object') {
    const keys = Object.keys(obj);
    const matchIndex = keys.indexOf(searchKey);
    if (matchIndex !== -1) {
      return obj[searchKey];
    }

    for (let i = 0, m = keys.length; i < m; ++i) {
      const key: string = keys[i];
      if (key in keyBlackList) {
        continue;
      }
      const potentialMatch = deepSearch(<SearchObject>obj[key], searchKey, keyBlackList);
      if (potentialMatch) {
        return potentialMatch;
      }
    }
  }
  return null;
}
