import { IGatsbyImageData } from "gatsby-plugin-image";

class TileItem {
  private _text: string;
  private _tooltip: string | null;
  private _link: string | null;
  private _image: IGatsbyImageData;

  constructor (text: string, image: IGatsbyImageData, tooltip: string | null = null, link: string | null = null) {
    this._text = text;
    this._tooltip = tooltip;
    this._link = link;
    this._image = image;
  }

  get text (): string {
    return this._text || '';
  }

  get image (): IGatsbyImageData {
    return this._image;
  }

  get tooltip (): string {
    return this._tooltip || '';
  }

  public isLink (): boolean {
    return this._link !== null;
  }

  get link (): string {
    return this._link || '';
  }
}

export { TileItem };
