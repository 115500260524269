import React, { PropsWithChildren, ReactElement, useContext } from 'react';

import { TileItem } from '../libs/tile-data';
import { deepSearch, SearchObject } from '../libs/utils';

import { Tiles } from '../components/tiles';
import { LocaleContext } from '../components/locale-context';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export type InlineTilesProps = PropsWithChildren<{ width?: string }>;

export default function InlineTiles ({ children, width }: InlineTilesProps): ReactElement {
  const { locale } = useContext(LocaleContext);

  const deepSearchBlackFilter = {
    _owner: true
  };
  const tiles = React.Children.toArray(children)
    .filter(c => typeof c !== 'string')
    .map(i => {
      const searchable = i as unknown as SearchObject;
      const title = deepSearch(searchable, 'alt', deepSearchBlackFilter);
      const tooltip = deepSearch(searchable, 'title', deepSearchBlackFilter);
      return new TileItem(title || '', i as unknown as IGatsbyImageData, tooltip || '');
    });
  return (
    <div className = "w3-padding-24">
      <Tiles tiles = { tiles } locale = { locale } width = { width }/>
    </div>
  );
}
