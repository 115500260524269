import { useStaticQuery, graphql } from 'gatsby';
import { LocalizedLabels, LocalizedObject } from '../components/locale-context';
import { pageInfoByLocale } from '../queries/page-info-helpers';
import { AllLocalesLabels } from './fragment-all-locales-labels';
import { PageInfoData } from './fragment-page-info';

interface NewsArticleLabelsData {
  labels: {
    nodes: AllLocalesLabels[]
  },
  page: PageInfoData
}

export const getNewsArticleLabels = (): LocalizedObject<LocalizedLabels> => {
  const { labels, page }: NewsArticleLabelsData = useStaticQuery(
    graphql`{
      labels: allLabelsTranslatedYaml(filter: {name: {in: [
          "sectionMediaAllNews"
      ]}}) {
        nodes {
          ...AllLocalesLabels
        }
      }
      page: pageIndexYaml (yamlId: {eq: "media"}) {
        ...PageInfo
      }
    }`
  );

  const byLocale: LocalizedObject<LocalizedLabels> = {};
  const ensureLocaleExists = (locale: string) => {
    if (!byLocale.hasOwnProperty(locale)) {
      byLocale[locale] = {};
    }
  };

  labels.nodes.forEach(l => {
    const label = l.name;
    const locales = l.locales;
    Object.keys(locales).forEach(locale => {
      ensureLocaleExists(locale);
      byLocale[locale][label] = locales[locale];
    });
  });

  const pageInfo = pageInfoByLocale(page);
  Object.keys(pageInfo).forEach(l => {
    byLocale[l]['title'] = pageInfo[l].title;
    byLocale[l]['titleTooltip'] = pageInfo[l].tooltip;
  });

  return byLocale;
}
