import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';

import { NewsItem } from '../libs/news-item';
import { LinkData } from "@chweb/commonlib";
import { NEWS_PAGE_PREFIX } from '../libs/news-helpers';

import NewsElement from '../components/one-news-section';
import { InterractiveSection } from "@chweb/commonui";
import WithMargins from '../components/with-margins';

import { localizeUrl } from '../libs/locale';
import { PageSEO } from '../libs/seo';
import { LocationInfo } from '../libs/location';
import { getLocalizedValue } from '../components/locale-context';
import { getNewsArticleLabels } from '../queries/get-media-article-labels';

import InlineTiles, { InlineTilesProps } from '../components/inline-tiles';

import RehypeReact from 'rehype-react';
import unified from 'unified';
import { Node } from 'unist';

// @ts-expect-error some weird RehypeReact typing issue
const astProcessor = unified().use(RehypeReact, {
  createElement: React.createElement,
  components: {
    tiles: (props: InlineTilesProps) => <InlineTiles {...props} />
  }
});

export const renderAst = (ast: Node): ReactElement => {
  return (astProcessor.stringify(ast) as unknown) as ReactElement 
}

interface MediaArticleProps {
  data: MediaArticleData,
  pageContext: { slug: string, locale: string},
  location: LocationInfo
}

export default function MediaArticle ({ data, pageContext, location }: MediaArticleProps): ReactElement {
  const url = localizeUrl(`/${NEWS_PAGE_PREFIX}${pageContext.slug}`);
  const newsData = new NewsItem(
    url,
    renderAst(data.md.htmlAst),
    new Date(data.md.frontmatter.date),
    data.md.frontmatter.source
  );
  if (data.md.frontmatter.title) {
    newsData.setTitle(data.md.frontmatter.title);
  }

  const labels = getNewsArticleLabels();
  const localLabels = getLocalizedValue(labels, pageContext.locale);

  const allNewsLink = localizeUrl(`/${NEWS_PAGE_PREFIX}/`, pageContext.locale);

  const linkData = new LinkData(allNewsLink, localLabels.sectionMediaAllNews);

  // this page should be treated as an article
  const seo = PageSEO.fromLocation(location);
  seo.isArticle = true;
  seo.description = data.md.excerpt;

  return (
    <Layout locale = { pageContext.locale } location={location} title = { data.md.frontmatter.title } seo = { seo }>
      <WithMargins margin="5%">
        <InterractiveSection
          title = { localLabels.title }
          bottomLink = { linkData }
          topLink = { linkData }
        >
          <NewsElement content = { newsData } key = { newsData.getId() } />
        </InterractiveSection>
      </WithMargins>
    </Layout>
  );
}

interface MediaArticleData {
  md: {
    htmlAst: Node,
    frontmatter: {
      title: string,
      date: string,
      source: string
    },
    excerpt: string,
    fields: {
      locale: string
    }
  }
}

export const mediaQuery = graphql`
query(
  $slug: String!
) {
    md: markdownRemark(fields: { slug: { eq: $slug } }) {
        htmlAst
        frontmatter {
          title
          date
          source
        }
        excerpt(pruneLength: 250)
        fields {
          locale
        }
    }
}`;
