export const NEWS_PER_PAGE = 6;
export const NEWS_PAGE_PREFIX = 'media';

export function pageCount (newsCount: number) {
  return Math.ceil(newsCount / NEWS_PER_PAGE);
}

export function latestNewsUrl(anchor = null) {
  if (anchor !== null) {
    return `/${NEWS_PAGE_PREFIX}#${anchor}`;
  } else {
    return `/${NEWS_PAGE_PREFIX}`;
  }
}
